.footer {
  width: 100%;
  background-color: #013b63;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}

.footer-upper-section {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

@media (min-width: 1024px) {
  .footer-upper-section {
    flex-direction: row;
    width: calc(100% - 144px);
    margin: 0 auto;
    justify-content: space-between;
  }
}

.footer-logo-img {
  display: block;
  width: 100%;
}

@media (min-width: 1024px) {
  .footer-logo {
    margin-right: 50px;
  }
}

@media (min-width: 1024px) {
  .footer-logo-img {
    width: 160px;
    cursor: pointer;
  }
}

.footer-navigation {
  margin-top: 20px;
  text-align: center;
}

@media (min-width: 1024px) {
  .footer-navigation {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .footer-navigation-list {
    display: flex;
    align-items: center;
  }
}

.footer-navigation li {
  padding: 15px 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .footer-navigation li {
    padding: 0 15px;
    font-family: "Gotham Medium";
    font-size: 12px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }
}

.footer-bottom-section {
  width: 100%;
  background-color: #000e2b;
  color: #fff;
  text-align: center;
  padding: 13px 0;
  font-size: 12px;
}

.poweredby {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .poweredby {
    justify-content: start;
  }
}
@media (min-width: 1024px) {
  .footer-logo-nav {
    display: flex;
    align-items: center;
  }
}

.poweredby p {
  color: #fff;
  margin-right: 5px;
  font-size: 12px;
}

.poweredby img {
  display: block;
  width: 24px;
}

.footer-social-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 0 15px;
}

@media (min-width: 1024px) {
  .footer-social-block {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .social-icon {
    padding: 0 10px;
  }
}

.social-icon img {
  width: 25px;
  cursor: pointer;
}
