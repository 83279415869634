.custom--dropdown-container {
  text-align: left;

  position: relative;
  border: none !important;
  cursor: pointer;
  height: 56px;
  width: 150px;
}

.custom--dropdown-container .dropdown-input-lsw {
  display: flex;
  align-items: center;
}

.custom--dropdown-container .dropdown-menu {
  width: 250px;

  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 0px 2px 6px 2px #00000026;
  padding: 0;
  box-shadow: 0px 1px 2px 0px #0000004d;
  z-index: 100;
  background-color: white;
}

.custom--dropdown-container .dropdown-item-lsw {
  cursor: pointer;
  width: 100%;
  padding: 0 !important;
  text-transform: capitalize;
  color: #171516;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.custom--dropdown-container .dropdown-item:hover {
  background-color: rgb(181, 181, 181);
}

.custom--dropdown-container .dropdown-item.selected {
  font-weight: 600;
}

.custom--dropdown-container .dropdown-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.focused {
  border: 2px solid #79747e;
}

.select-container-lsw {
  position: relative;
  cursor: pointer;
  text-transform: uppercase !important;
}

.selected-value-lsw {
  height: 36px;
  border: none !important;
  font-size: 12px !important;
  font-family: "Gotham Medium";
  font-weight: bold;
  text-transform: uppercase !important;
  display: flex;
  align-items: center;
}

.focused {
  border: 2px solid #79747e;
}

.options-container-lsw {
  position: absolute;

  border-radius: 4px;
  top: 50px !important;

  left: -30px !important;
  box-shadow: 0px 2px 6px 2px #00000026;
  padding: 0;
  box-shadow: 0px 1px 2px 0px #0000004d;
  z-index: 100;
  background-color: white;
  width: 70px;
}

.options-container-lsw li {
  padding: 10px !important;
  text-transform: uppercase !important;
  color: #171516;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.options-container-lsw li:hover {
  background-color: #55c9ea !important;
}

.delete-btn {
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.delete-btn img {
  display: block;
  width: 20px;
}

.select-label-lsw {
  position: absolute;
  padding: 0 3px;
  left: 20px;
  top: -6px;
  font-size: 12px;
  height: 20px;
  background-color: white;
  z-index: 20;
  font-weight: 400;
}

.triangle-lsw {
  background: transparent;
  outline: none;
  border: none;

  margin-right: 5px;
}

.triangle-lsw img {
  display: block;
  width: 13px;
}

.triangle-reverse {
  transform: rotate(180deg);
}

.flag-icon {
  display: flex;
  width: 20px;
}
