.page-container {
  width: calc(100% - 48px);
  margin: 0 auto;
  margin-top: 20px;
  min-height: 600px;
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .page-container {
    width: calc(100% - 144px);
    margin: 0 auto;
    margin-top: 20px;

    margin-bottom: 100px;
  }
}

.form-container {
  width: calc(100% - 48px);
  margin: 0 auto;
  margin-top: 20px;

  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .form-container {
    width: 65%;
    max-width: 640px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

.loadingbar {
  width: 100%;
  height: 4px;

  background: #e6e0e9;
  position: relative;
  margin-bottom: 50px;
}

.loadingbar-fill {
  background-color: #55c9ea;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.refund-desc {
  margin-bottom: 30px;
}

.refund-desc h4 {
  font-family: "Gotham Book";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
  color: #013b63;
}

@media (min-width: 768px) {
  .refund-desc h4 {
    font-family: "Gotham Medium";
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    color: #000e2b;
  }
}

.refund-desc p {
  color: #000000;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0.004em;
  text-align: left;
}

.refund-desc .form-description {
  color: #000000;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0.004em;
  text-align: left;
}

.form-container .form-description {
  color: #000000;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 350;
  line-height: 18px;
  letter-spacing: 0.004em;
  text-align: left;
}

@media (min-width: 768px) {
  .refund-desc .form-description {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .form-container .form-description {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

.next-step-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .next-step-button-container {
    justify-content: start;
    padding: 0;
  }
}

.next-step-button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #55c9ea;
  color: #000e2b;
  font-size: 14px;
  padding: 13px 25px;
  text-align: center;
  font-weight: bold;
  border-radius: 100px;
}

.back-button {
  outline: none;
  border: none;
  background-color: #e8def8;
  width: 85px;
  color: #000e2b;
  font-size: 14px;
  padding: 13px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 100px;
  margin-right: 30px;
  cursor: pointer;
}

.terms-policy-link {
  margin-top: 24px;

  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.terms-policy-link-highlight {
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.terms-policy-link-highlight-2 {
  font-family: "Gotham Medium";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}
.homepage-hero-slogan {
  color: #013b63;
  font-family: "Gotham Medium";
}

.homepage-hero-slogan-block .terms-policy-link-highlight-2 {
  font-family: "Gotham Medium";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.step-indicator {
  position: absolute;
  right: 0;
  top: -20px;
  font-family: "Gotham Medium";
}
