.page-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #013b63;
}

.eligibility-descriptions {
  margin-top: 24px;
}

.eligibility-description {
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
  margin-bottom: 30px;
  color: #000000;
}

@media (min-width: 768px) {
  .eligibility-description {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

.hightlight-text {
  font-family: "Gotham Medium";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

@media (min-width: 768px) {
  .hightlight-text {
    font-family: "Gotham Medium";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

.hightlight-text-blue {
  font-family: "Gotham Medium";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
  color: #55c9ea;
}

@media (min-width: 768px) {
  .hightlight-text-blue {
    font-family: "Gotham Medium";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #55c9ea;
  }
}

.page-title {
  font-family: "Gotham Book";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
  color: #013b63;
}

@media (min-width: 768px) {
  .page-title {
    font-family: "Gotham Medium";
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    color: #000e2b;
  }
}
