.how-it-works-posters {
  margin-top: 50px;
  margin-bottom: 84px;
}

@media (min-width: 1024px) {
  .how-it-works-posters {
    display: flex;
    justify-content: space-between;
  }
}

.how-it-works-poster {
  background-color: #000e2b;
  border-radius: 24px;
  padding: 24px;
}

@media (min-width: 1024px) {
  .how-it-works-poster {
    width: 27%;
  }
}

.how-it-works-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .how-it-works-posters {
    display: flex;
  }
}

.how-it-works-icon img {
  display: block;
  width: 48px;
}

.poster-texts {
  color: white;
  font-family: "Gotham Medium";
}

.poster-title-wrapper {
  display: flex;
  margin-bottom: 30px;
  font-family: "Gotham Medium";
}

.poster-title-wrapper .number {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  margin-right: 5px;
}

.poster-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.poster-title-span {
  display: block;
  padding-left: 15px;
}

.poster-texts .poster-description {
  color: white;
  font-family: Gotham;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.thick-arrow {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

@media (min-width: 1024px) {
  .thick-arrow {
    width: 64px;
    align-items: center;
  }
}

.thick-arrow img {
  display: block;
  width: 64px;
}

@media (min-width: 1024px) {
  .thick-arrow img {
    width: 100%;
    height: 70px;
    transform: rotate(-90deg);
  }
}

.big-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #000e2b;
}

.ready-to-taxrefund {
  border: 1px solid #013b63;
  padding: 24px;
  margin-top: 120px;
}

.ready-to-taxrefund .ready-to-taxrefund-description {
  text-align: center;
  font-family: "Gotham Book";
  color: #000e2b;

  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: center;
}

.page-margin {
  margin-top: 100px;
}

.list .hightlight-text {
  font-family: "Gotham Medium";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.hightlight-text {
}

@media (min-width: 768px) {
  .list .hightlight-text {
    font-family: "Gotham Medium";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}
