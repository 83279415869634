.activity-inputs-wrapper {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.activity-input-wrapper {
  width: 45%;
  position: relative;
}

.activity-input {
  width: 100%;
  border: 1px solid #79747e;
  height: 56px;
  border-radius: 4px;
  outline: none;
  padding: 0 5px;
  font-size: 18px;
}

.activity-input::placeholder {
  opacity: 0.5;
  font-size: 12px;
}

.activity-label {
  background-color: white;
}

.activity-input:focus {
  border: 2px solid #79747e;
  outline: none;
}

.percent-label {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
