.payment-component-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
  color: #013b63;
}

@media (min-width: 768px) {
  .payment-component-title {
    font-family: "Gotham Medium";
    color: #000e2b;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.payment-buttons-wrapper {
  display: flex;

  height: 40px;
  width: 100%;
  margin-top: 48px;

  border: 1px solid #79747e;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.payment-buttons-wrapper li {
  flex: 1;
}

.payment-button {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: "Gotham Medium";
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  cursor: pointer;
}

#payment-button-1 {
  border-right: 1px solid #79747e;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

#payment-button-2 {
  border-right: 1px solid #79747e;
}

#payment-button-3 {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.personal-information-form {
  width: 100%;
  margin-top: 48px;
}

.regular-input {
  width: 100%;
  height: 56px;
  border: 1px solid #79747e;
  border-radius: 4px;
  padding: 0 5px;
}

.form-message {
  height: 100px;
  font-family: "Gotham Book";
  padding: 10px 5px;
}

.regular-input-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.regular-input:focus {
  border: 2px solid #79747e;
  outline: none;
}

.payment-button-check {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);
}

.payment-button-check img {
  display: block;
  width: 16px;
}

.payment-button-disabled {
  opacity: 0.5;
  cursor: auto;
}

.payment-error {
  font-family: "Gotham book";
  margin-top: 10px;
  color: red;
  font-size: 12px;
}
