.refund-desc .text-regular {
  color: #000;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

@media (min-width: 768px) {
  .refund-desc .text-regular {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

.validation-error {
  color: red !important;
  font-size: 10px;
  position: absolute;

  left: 0;
}

.message-sent h4 {
  font-size: 32px;
  margin-top: 50px;
}
