.veg-stamp {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 100px;
}

.veg-stamp img {
  display: block;
  width: 120px;
}

@media (min-width: 768px) {
  .veg-stamp {
    margin: 0;

    display: block;
  }
}

@media (min-width: 768px) {
  .veg-stamp img {
    width: 240px;
  }
}

.text-regular {
  color: #000;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

@media (min-width: 768px) {
  .who-we-are-stamp-block {
    width: 100%;
    display: flex;
    margin-top: 150px;
    justify-content: flex-start;
  }
}
