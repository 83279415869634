.receipt-wrapper {
  margin-top: 48px;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0px 4px 15px 0px #000e2b1f;
  padding: 48px 0;
}

.receipt-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #000e2b;
  padding: 0 24px;
  margin-bottom: 24px;
}

.no-pointer {
  cursor: auto;
}

.price-description {
  padding: 0 24px;
  margin-bottom: 24px;
}

.price-description-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-price-item {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: right;
}

#sub-price-item-2 {
  margin-right: 5px;
}

.divider {
  display: block;
  width: 100%;
  width: calc(100% - 48px);
  background-color: #cac4d0;
  height: 1px;
  margin: 0 auto;
}

.total-wrapper {
  padding: 0 24px;
  margin-top: 24px;
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
