.homepage {
  margin-bottom: 100px;
}

.homepage-hero {
  display: flex;
  flex-direction: column;

  margin-top: 32px;
  padding: 0 24px;
}

@media (min-width: 768px) {
  .homepage-hero {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 72px;
    margin-top: 100px;
  }
}

.homepage-hero-image {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 768px) {
  .homepage-hero-image {
    justify-content: start;
    min-width: 35%;
    width: 40%;
    margin-right: 100px;
    max-width: 435px;
  }
}

.homepage-hero-image img {
  display: block;
  width: 240px;
}

@media (min-width: 768px) {
  .homepage-hero-image img {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}

.homepage-hero-slogan-block {
  margin-top: 42px;
  color: #000e2b;
}

@media (min-width: 768px) {
  .homepage-hero-slogan-block {
    margin: 0;
  }
}

.homepage-hero-slogan {
  font-family: "Gotham Medium";
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  color: #013b63;
}

.homepage-hero-slogan-1 {
  display: block;
}

.homepage-hero-slogan-text {
  margin-top: 32px;
  font-family: "Gotham Book";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

@media (min-width: 768px) {
  .homepage-hero-slogan-text {
    width: 70%;
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .homepage-hero-slogan {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.get-tax-refund-btn {
  display: block;
  border: none;
  outline: none;
  background-color: #55c9ea;
  color: #000e2b;
  border-radius: 100px;
  align-self: flex-start;
  font-size: 14px;

  width: auto;
  padding: 13px 15px;
  margin-top: 24px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Gotham Medium";
}

@media (min-width: 768px) {
  .get-tax-refund-btn {
    font-family: "Gotham Medium";
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }
}

.how-it-works-block {
  position: relative;
  margin-top: 80px;
  width: 100%;
  height: 420px;
  display: flex;
}

@media (min-width: 768px) {
  .how-it-works-block {
    margin-top: 140px;
    height: auto;
  }
}

.cloud {
  width: 155px;
  height: 100%;
}

@media (min-width: 768px) {
  .cloud {
    width: 500px;
    max-width: 500px;
  }
}

.mobile-cloud {
  display: block;
  width: 100%;
}

.desk-cloud {
  display: none;
}

@media (min-width: 768px) {
  .mobile-cloud {
    display: none;
  }
}

@media (min-width: 768px) {
  .desk-cloud {
    width: 100%;
    display: block;
  }
}

.how-it-works-block-title {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  font-family: "Gotham Medium";
  font-size: 22px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: "#000E2B";
  padding-left: 20px;
}

@media (min-width: 768px) {
  .how-it-works-block-title {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.how-it-works-subtitle-1 {
  position: absolute;
  top: 115px;
  left: 120px;
  display: flex;
  align-items: center;
}

.how-it-works-subtitle-1 img {
  display: block;
  width: 24px;
  margin-right: 20px;
}

@media (min-width: 768px) {
  .how-it-works-subtitle-1 {
    width: auto;
    position: absolute;
    top: 225px;
    left: 350px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-1 img {
    width: 48px;
    margin-right: 40px;
  }
}

.how-it-works-subtitle-1 p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: black;
  font-family: "Gotham Medium";
}

@media (min-width: 768px) {
  .how-it-works-subtitle-1 p {
    font-family: "Gotham Medium";
    font-size: 24px;
    font-weight: 350;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.how-it-works-subtitle-2 {
  position: absolute;
  top: 190px;
  left: 105px;
  display: flex;
  align-items: center;
}

.how-it-works-subtitle-3 {
  position: absolute;
  top: 255px;
  left: 115px;
  display: flex;
  align-items: center;
}

.how-it-works-subtitle-2 img {
  display: block;
  width: 24px;
  margin-right: 20px;
}

.how-it-works-subtitle-2 p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: black;
  font-family: "Gotham Medium";
}

.how-it-works-subtitle-3 img {
  display: block;
  width: 24px;
  margin-right: 20px;
}

.how-it-works-subtitle-3 p {
  color: black;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  font-family: "Gotham Medium";
}

@media (min-width: 768px) {
  .how-it-works-subtitle-2 {
    width: auto;
    position: absolute;
    top: 325px;
    left: 430px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-2 img {
    width: 48px;
    margin-right: 40px;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-2 p {
    font-family: "Gotham Medium";
    font-size: 24px;
    font-weight: 350;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-3 {
    width: auto;
    position: absolute;
    top: 445px;
    left: 430px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-3 img {
    width: 48px;
    margin-right: 40px;
  }
}

@media (min-width: 768px) {
  .how-it-works-subtitle-3 p {
    font-family: "Gotham Medium";
    font-size: 24px;
    font-weight: 350;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.btn-positioned {
  position: absolute;

  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.customer-revie-block {
  font-family: "Gotham Book";
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-top: 100px;
}

@media (min-width: 768px) {
  .customer-revie-block {
    display: none;
  }
}

.customer-revie-block-desktop {
  display: none;
}

@media (min-width: 768px) {
  .customer-revie-block-desktop {
    display: block;
    font-family: "Gotham Book";
    font-size: 24px;
    font-style: italic;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 140px;
  }
}

.left-lapka {
  width: 35px;
  position: absolute;
  top: -25px;
  left: -35px;
}

.left-lapka img {
  display: block;
  width: 100%;
}

.right-lapka {
  width: 35px;
  position: absolute;
  bottom: -20px;
  right: -75px;
}

.right-lapka img {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .left-lapka {
    width: 59px;
    position: absolute;
    top: -65px;
    left: 0;
  }
}

@media (min-width: 768px) {
  .right-lapka {
    width: 59px;
    position: absolute;
    bottom: -30px;
    right: -230px;
  }
}

.who-we-are-block {
  margin-top: 140px;
}

.who-we-are-title {
  font-family: "Gotham Medium";
  font-size: 24px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

@media (min-width: 768px) {
  .who-we-are-title {
    font-family: "Gotham Medium";
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #000e2b;
  }
}
