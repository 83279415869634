.checkboxes-block {
  margin-bottom: 40px;
}

.checkboxes-title {
  font-family: "Gotham Medium";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.005em;
  text-align: left;
}

.info-icon {
  width: 18px;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}

.info-icon img {
  display: block;
  width: 100%;
}

.show-info {
  display: none;
  position: absolute;
  right: 100%;
  bottom: 100%;

  background-color: #322f35;
  width: 200px;
  font-size: 12px;
  color: white;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px #0000004d;

  box-shadow: 0px 4px 8px 3px #00000026;
}

.info-icon:hover > .show-info {
  display: block;
}

#avs-and-annuities-pensions {
  bottom: 200%;
  right: -350%;
}

#LPP {
  bottom: 200%;
  right: -300%;
}

#threeA {
  bottom: 200%;
  right: -300%;
}

#sick-leave {
  right: -200%;
  bottom: 150%;
}

.partner-label {
  font-family: "Gotham Book";
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
