.page-hero {
  display: flex;
  flex-direction: column;

  margin-top: 32px;
  padding: 0 24px;
}

@media (min-width: 768px) {
  .page-hero {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-top: 150px;
  }
}

@media (min-width: 768px) {
  .refund-desc .form-description {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .form-container .form-description {
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .homepage-hero-slogan-block .homepage-hero-slogan-text {
    width: 70%;
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-align: left;
  }
}
