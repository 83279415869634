.header {
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .header {
    width: calc(100% - 144px);
    margin: 0 auto;
    padding: 24px 0;
    border-bottom: 1px solid #000e2b;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  width: 43%;
  max-width: 170px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .logo {
    margin-right: 50px;
  }
}

.logo img {
  display: block;
  width: 100%;
}

.header-functions-block {
  display: flex;
  align-items: center;
}

.language-switcher {
  cursor: pointer;
}

.language-switcher-value {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.language-switcher-value img {
  display: block;
  width: 9px;
  height: 5px;
  margin-right: 12px;
}

.language-switcher-value span {
  font-size: 14px;
  color: #000e2b;
  font-weight: bold;
}

.hamburger {
  width: 24px;
  justify-self: end;
  margin-left: 10px;
}

@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }
}

.hamburger img {
  display: block;
  width: 100%;
}

.logo-navbar-wrapper {
  display: flex;
  align-items: center;
}
