.transportation-mean-wrapper {
  margin-bottom: 20px;
  width: 100%;
}

.transport-mean-header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
