@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url("../public/fonts/Gotham-Regular.ttf");
}

@font-face {
  font-family: "Gotham Bold";
  src: local("Gotham Bold"), url("../public/fonts/Gotham-Bold.otf");
}

@font-face {
  font-family: "Gotham Book";
  src: local("Gotham Book"), url("../public/fonts/Gotham-Book.otf");
}

@font-face {
  font-family: "Gotham Light";
  src: local("Gotham Light"), url("../public/fonts/Gotham-Light.otf");
}

@font-face {
  font-family: "Gotham Medium";
  src: local("Gotham Medium"), url("../public/fonts/Gotham-Medium.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Gotham";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
nav {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.App {
  width: 100%;
}

@media (min-width: 768px) {
  .fixed-height {
    min-height: 65vh;
  }
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.8;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}
