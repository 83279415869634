.custom--dropdown-container {
  text-align: left;
  border: 1px solid #79747e;
  margin-top: 20px;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  height: 56px;
  width: 100%;
}

.custom--dropdown-container .dropdown-input {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  height: 100%;
}

.custom--dropdown-container .dropdown-menu {
  width: 100%;

  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;

  background-color: #fff;

  box-shadow: 0px 2px 6px 2px #00000026;
  padding: 0;
  box-shadow: 0px 1px 2px 0px #0000004d;
  z-index: 100;
  background-color: white;
}

.custom--dropdown-container .dropdown-item {
  cursor: pointer;
  width: 100%;
  padding: 15px 20px;
  text-transform: capitalize;
  color: #171516;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.custom--dropdown-container .dropdown-item:hover {
  background-color: rgb(181, 181, 181);
}

.custom--dropdown-container .dropdown-item.selected {
  font-weight: 600;
}

.custom--dropdown-container .dropdown-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 5px;
}

.focused {
  border: 2px solid #79747e;
}

.select-container {
  position: relative;
  cursor: pointer;
}

.selected-value {
  width: 100%;
  height: 56px;
  border: 1px solid #79747e;
  border-radius: 4px;
  font-size: 16px;
  padding: 15px 10px;
}

.focused {
  border: 2px solid #79747e;
}

.options-container {
  position: absolute;
  border-radius: 4px;
  top: 57px;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 6px 2px #00000026;
  padding: 0;
  box-shadow: 0px 1px 2px 0px #0000004d;
  z-index: 100;
  background-color: white;
}

.options-container li {
  padding: 15px 20px;
  text-transform: capitalize;
  color: #171516;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.options-container li:hover {
  background-color: rgb(181, 181, 181);
}

.delete-btn {
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.delete-btn img {
  display: block;
  width: 20px;
}

.select-label {
  position: absolute;
  padding: 0 3px;
  left: 10px;
  top: -6px;
  font-size: 12px;
  height: 14px;
  background-color: white;
  z-index: 20;
  font-weight: 400;
}
