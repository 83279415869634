.validation-error {
  color: red !important;
  font-size: 10px;
  position: absolute;

  left: 0;
}

.validation-error p {
  color: red !important;
}
