.alimony-inputs-wrapper {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.alimony-input-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
