.navigation {
  width: 80%;
  background-color: #000e2b;
  opacity: 0.97;
  color: white;
  height: 100%;
  padding: 24px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  z-index: 100;
  transition: left 0.7s ease;
}

@media (min-width: 1024px) {
  .navigation {
    display: none;
  }
}

.navigation-open {
  left: 0;
}

.navigation-logo {
  width: 63%;
  max-width: 170px;
}

.navigation-logo img {
  display: block;
  width: 100%;
}

.navigation-list {
  margin-top: 10px;
  padding-left: 20px;
}

.navigation-list li {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 400;
}

.navigation-list li:hover {
  color: #55c9ea;
}

@media (min-width: 1024px) {
  .navigation-list li {
  }
}

@media (min-width: 1024px) {
  .navigation-list li {
    font-size: 14px;
    padding: 0 17px;
    font-family: "Gotham Medium";
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
  }
}

.header-poweredby {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
}

.header-poweredby img {
  display: block;
  width: 24px;
  margin-left: 5px;
}

.desktop-navigation {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-navigation {
    display: block;
  }
}

@media (min-width: 1024px) {
  .navigation-list {
    margin: 0;
    padding: 0;
    display: flex;
  }
}

.nav-li-text {
  cursor: pointer;
}
