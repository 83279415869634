.privacy-block {
  margin-top: 30px;
  width: 100%;
  padding: 0;
}

.privacy-block .privacy-description {
  margin-top: 15px;
  font-family: "Gotham Book";
}

@media (min-width: 768px) {
  .privacy-block {
    width: 100%;
    padding: 0 30px;
  }
}

.priv-title {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
