.dependent-children-inputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
}

.delete-dependent-child-button {
  width: 20px;
  height: 20px;
  background: transparent;
  outline: none;
  border: none;
}

.delete-dependent-child-button img {
  width: 100%;
  display: block;
  cursor: pointer;
}
