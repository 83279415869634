input::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  display: none;
}

input[type="file"] {
  display: none;
}

.file-input-wrapper {
  width: 100%;

  margin-bottom: 20px;
}

.file-input-label {
  width: 90%;
  font-family: "Gotham Medium";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  text-transform: capitalize;
}

.file-input-button {
  width: 12px;
  display: block;
  cursor: pointer;
}

.file-input-button img {
  display: block;
  width: 100%;
}

.filename {
  padding-left: 20px;
  display: flex;

  width: 90%;
  font-family: Gotham;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
}

.delete-file-button {
  width: 12px;
  display: block;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
}

.delete-file-button img {
  display: block;
  width: 100%;
}

.filename-text {
  margin-right: 10px;
}
