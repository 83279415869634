.dependentchildren-block {
  margin-top: 40px;
}

.input-title {
  font-family: Gotham;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.004em;
  text-align: left;
}

.add-btn-block {
  display: flex;

  margin-top: 20px;
}

.add-btn {
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  color: #013b63;
  margin-right: 15px;
  cursor: pointer;
}

.no-checkbox-block {
  display: flex;
  margin-bottom: 15px !important;
  align-items: center;
}

.marginZero {
  margin-bottom: 0 !important;
}

.no {
  font-size: 14px;
  color: #013b63;
  font-weight: bold;
}

.no-checkbox {
  box-sizing: border-box;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid black;
  margin-right: 5px;
  margin-bottom: -1px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
}

.no-checkbox-mark {
  position: absolute;
  background-color: #013b63;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.check-mark-icon {
  display: block;
  width: 15px;
}

.single-parent-hausehold-block {
  margin-top: 40px;
}

.single-parent-hausehold-boxes {
  padding-top: 5px;
  margin-top: 15px;
}

.horisontal {
  width: 100%;
  display: flex;
  padding-left: 20px;
}

.column {
  flex-direction: column;
}
